import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Image from 'next/image';
import { useEffect } from 'react';
import Link from 'next/link';

export default function Footer() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      {' '}
      <div className="bg-gradient-to-tr from-[#09b1d9] to-[#0293b5] text-gray-200">
        <div className={`max-w-7xl mx-auto px-4 pt-12`}>
          <div className="grid grid-cols-2 gap-10 lg:gap-0 lg:grid-cols-5">
            <div className="col-span-2 lg:col-span-1" data-aos="fade-up">
              <div className={`text-2xl md:text-4xl font-bold text-white`}>
                Өсөх-Од
              </div>
              <p className="mt-6 w-60">
                Таны өөртөө хийх хамгийн зөв хөрөнгө оруулалт бол спорт юм.
              </p>
              <div className="flex mt-6">
                <a
                  target="_blank"
                  href="https://www.facebook.com/usukhodtabletennis.club"
                >
                  <img
                    src="/assets/social/facebook.png"
                    className="mr-5 h-8 w-8 text-white hover:scale-105"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/usuhod_tt/">
                  <img
                    src="/assets/social/instagram.png"
                    className="mr-5 h-8 w-8 hover:scale-105"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@usuh-odtabletennis952"
                >
                  <img
                    src="/assets/social/youtube.png"
                    className="mr-5 h-8 w-8 transform hover:scale-105"
                  />
                </a>
              </div>
            </div>

            <div>
              <h1 className="text-yellowc font-bold text-xl" data-aos="fade-up">
                Үндсэн
              </h1>
              <div className="mt-6 text-[13px]">
                <Link href={'/'} target="_blank" className="hover:underline">
                  <p className="mt-2">Нүүр хуудас</p>
                </Link>
                <Link href={'/courses'} className="hover:underline">
                  <p className="mt-2">Сургалт</p>
                </Link>
                <Link href={'/shop'} className="hover:underline">
                  <p className="mt-2">Дэлгүүр</p>
                </Link>
                <Link href={'/aboutus'} className="hover:underline">
                  <p className="mt-2">Бидний тухай</p>
                </Link>
              </div>
            </div>
            <div>
              <h1 className="text-yellowc font-bold text-xl" data-aos="fade-up">
                Тусламж
              </h1>
              <div className="mt-6 text-[13px]">
                <Link href={'/FAQ'} className="hover:underline">
                  <p className="mt-2">Түгээмэл асуулт & хариулт</p>
                </Link>
                <Link href={'/terms'} className="hover:underline">
                  <p className="mt-2">Үйлчилгээний нөхцөл</p>
                </Link>
                <Link href={'/privacy'} className="hover:underline">
                  <p className="mt-2">Нууцлалын бодлого</p>
                </Link>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <h1 className="text-yellowc font-bold text-xl" data-aos="fade-up">
                Холбоо барих{' '}
              </h1>

              <div className="mt-6 mr-0 lg:mr-10 space-y-2 text-[13px]">
                <div>
                  <a
                    href="mailto:info@edulinellc.mn"
                    className="flex items-center justify-start"
                  >
                    <MailIcon className="h-6" />
                    <b className="pr-1">{'Имэйл'}:</b>enkomon@gmail.com
                  </a>
                </div>
                <hr className="border-dashed mt-2 pb-1" />
                <div>
                  <div className="flex items-start">
                    <b className="pl-1 pr-2">{'Хаяг'}:</b>
                    {
                      'Баянзүрх дүүрэг 26-р хороо Дүнжингаравын урд Чухаг хотхон - Чухаг спорт төв.'
                    }
                  </div>
                  <div className="flex items-start ">
                    <b className="pr-2 pl-1">{'Утас'}:</b>
                    <p>85-02-68-02</p>
                  </div>
                  {/* <hr className="border-dashed mt-2 pb-1" /> */}
                </div>
                {/* <div>
                  <div className="flex items-start">
                    <img
                      src="https://i2.wp.com/edulinellc.mn/wp-content/uploads/2020/08/kr-flag-mini.png?resize=32%2C21"
                      className="h-4 w-6 mt-1"
                    />
                    <b className="pl-1 pr-2">{'Хаяг'}:</b>
                    {'Seoul, Gangseo-gu, Yangcheon-ro 551-24, 305'}
                  </div>
                  <div className="flex items-start pl-6">
                    <b className="pr-2 pl-1">{'Утас'}:</b>
                    <p>010-6632-3266, 070-7151-1289</p>
                  </div>
                  <hr className="border-dashed mt-2 pb-1" />
                </div> */}
                {/* <div>
                  <div className="flex items-start">
                    <img
                      src="https://i0.wp.com/edulinellc.mn/wp-content/uploads/2020/08/vn.png?resize=32%2C21"
                      className="h-4 w-6 mt-1"
                    />
                    <b className="pl-1 pr-2">{'Хаяг'}:</b>
                    {
                      'Hanoi, Nam Tu Liem District, Pham Phuong Streete, Song Da Building, Floor 26'
                    }
                  </div>
                  <div className="flex items-start pl-6">
                    <b className="pr-2 pl-1">{'Утас'}:</b>
                    <p>84-35-464-9571</p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <h1 className="text-yellowc font-bold text-xl" data-aos="fade-up">
                Апп татах
              </h1>
              <div className="mt-6 text-base">
                <img
                  className="xl:block hidden rounded-lg"
                  src="/assets/social/Qr-code.png"
                  alt=""
                />
                <div className="flex mt-2 justify-between w-100">
                  <a href="https://apps.apple.com/mn/app/usukh-od/id1664013895">
                    <img
                      className="md:h-10 h-10"
                      src="/assets/social/appStore.jpg"
                      alt="usukhod-app"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=mn.clubi.usukhod">
                    <img
                      className="md:h-10 h-10"
                      src="/assets/social/playStore.jpg"
                      alt="usukhod-app"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center py-4 border-t mt-4">
          © Зохиогчийн эрхийг хуулийн дагуу Өсөх-Од клуб хариуцна.
        </p>
      </div>
    </>
  );
}
