import axios from 'axios';
import { useApiHandler, fetcherNoToken } from './apiHandler';
import useSWRImmutable from 'swr/immutable';

export const useGetAllCategory = ({ subUrl }) => {
  const { data, error, ...rest } = useSWRImmutable(
    [process.env.BASE_API_URL + subUrl],
    fetcherNoToken
  );
  return { data, error, loading: !data && !error, ...rest };
};

export const useGetAllBrand = ({ subUrl }) => {
  const { data, error, ...rest } = useSWRImmutable(
    [process.env.BASE_API_URL + subUrl],
    fetcherNoToken
  );
  return { data, error, loading: !data && !error, ...rest };
};

const getCartData = () =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/cart/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const getAllProductData = () =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/product/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const getCurrnetProductData = id =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/product/${id}`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const postAddProductIntoCard = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/api/sales/cart/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const putUpdateCartItem = (data, id, accessToken) =>
  axios.put(`${process.env.BASE_API_URL}/api/sales/cart-item/${id}`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getCartInfo = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/cart/checkout`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const deleteRemoveCart = accessToken =>
  axios.delete(`${process.env.BASE_API_URL}/api/sales/cart/remove/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const createBooking = (data, id, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/api/sales/order/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getOrderInfo = (id, accessToken) =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/order/${id}/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const postCreateInvoice = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/api/sales/create_invoice/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getAllOrder = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/api/sales/order/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const postCheckPayment = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/api/sales/check_invoice/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      // Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

export const useGetCartData = () => useApiHandler(getCartData);
export const useGetAllProductData = () => useApiHandler(getAllProductData);
export const useGetCurrentProductData = () =>
  useApiHandler(getCurrnetProductData);
export const usePostAddProductIntoCard = () =>
  useApiHandler(postAddProductIntoCard);
export const usePutUpdateCartItem = () => useApiHandler(putUpdateCartItem);
export const useGetCartInfo = () => useApiHandler(getCartInfo);
export const useDeleteRemoveCart = () => useApiHandler(deleteRemoveCart);
export const useCreateBooking = () => useApiHandler(createBooking);
export const useGetOrderInfo = () => useApiHandler(getOrderInfo);
export const usePostCreateInvoice = () => useApiHandler(postCreateInvoice);
export const useGetAllOrder = () => useApiHandler(getAllOrder);
export const usePostCheckPayment = () => useApiHandler(postCheckPayment);
