import Head from 'next/head';
import Footer from './footer';
import Navbar from './navbar';

export default function Layout({ children }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Ширээний теннис сургалт, спортын хувцас захиалга, худалдаа"
        />
        <meta name="title" content="Usukh-Od" />
        <meta name="category" content="class, shop, courses" />
        <meta name="subject" content="courses" />
        <meta
          name="keywords"
          content="Ширээний теннис сургалт, спортын хувцас захиалга, худалдаа"
        />
        <meta property="og:title" key="ogtitle" content="Usukh-Od" />
        <meta property="og:locale" key="oglocale" content="mn_MN" />
        <meta property="og:phone_number" content="85026802" />
        <meta
          property="og:street-address"
          content="Баянзүрх дүүрэг 26-р хороо Дүнжингаравын урд Чухаг хотхон - Чухаг спорт төв."
        />
        <meta property="og:locality" content="Ulaanbaatar" />
        <meta property="og:region" content="UB" />
        <meta property="og:country-name" content="Mongolia" />
        <meta property="og:email" content="enkomon@gmail.com" />
        <meta property="og:url" key="ogurl" content="https://usukh-od.com/" />
        <meta
          property="og:image"
          content="https://usukh-od.com/assets/bannerimg/WEB_PHOTO_03.jpg"
        />
        <meta property="og:type" key="ogtype" content="website" />
        <meta
          property="og:description"
          key="ogdesc"
          content="Ширээний теннис сургалт, спортын хувцас захиалга, худалдаа"
        />
        <title>Usukh-Od Club</title>
      </Head>
      <main className="bg-bg min-h-screen">
        <Navbar />
        {children}
        <Footer />
      </main>
    </>
  );
}
