import React from 'react';
import { Listbox } from '@headlessui/react';
import {
  UserIcon,
  AcademicCapIcon,
  PencilIcon,
  BookOpenIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon, LogoutIcon } from '@heroicons/react/solid';
import { useAuthState } from '@context/auth';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useData } from '@context/product';

export default function Profile({ name }) {
  const { storage, setValues } = useData();
  const router = useRouter();
  const { pathname } = router;
  const { setLogout } = useAuthState();
  function logOut() {
    setValues({ cartNumber: '0' });
    setValues({ cartRoute: '' });
    setLogout();
    router.push('/');
  }
  return (
    <Listbox value={''}>
      <div className="cursor-pointer relative flex justify-center lg:justify-start text-urlHover">
        <Listbox.Button
          className={`flex border border-white/30 hover:bg-white/30 items-center text-sm text-white font-semibold px-3 py-2 gap-1 rounded-lg`}
        >
          <UserIcon className="h-5 text-white" />
          <p className="hidden md:block">{name}</p>
          <ChevronDownIcon className="h-4 text-white" />
        </Listbox.Button>
        <Listbox.Options
          className={
            'absolute mt-10 w-48 bg-white rounded-sm shadow-lg right-0'
          }
        >
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <div className="w-full">
                <Link href="/user/info">
                  <div className="flex items-center text-sm gap-2 px-4 py-3 hover:bg-hover/10 hover:text-hover text-gray-700 w-full">
                    <UserIcon className="h-4" /> Миний самбар
                  </div>
                </Link>
                <Link href="/user/order">
                  <div className="flex items-center text-sm gap-2 px-4 py-3 hover:bg-hover/10 hover:text-hover text-gray-700 w-full">
                    <BookOpenIcon className="h-4" /> Миний захиалгууд
                  </div>
                </Link>
                {/* <Link href="/">
                  <div className="flex items-center text-sm gap-2 px-4 py-3 hover:bg-hover/10 hover:text-hover text-gray-700 w-full">
                    <PencilIcon className="h-4" /> Мэдээлэл засах
                  </div>
                </Link> */}
                <Link href="/user/course">
                  <div className="flex items-center text-sm gap-2 px-4 py-3 hover:bg-hover/10 hover:text-hover text-gray-700 w-full">
                    <AcademicCapIcon className="h-4" />
                    Сургалтын мэдээлэл
                  </div>
                </Link>
              </div>
            )}
          </Listbox.Option>
          <hr />
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <div
                onClick={() => logOut()}
                className="py-3 px-4 w-full hover:bg-red-100 hover:text-urlHover"
              >
                <div className="flex items-center justify-start gap-x-2 text-medium font-medium">
                  <LogoutIcon className="text-red-400 h-4" />
                  <span className="text-red-400">Гарах</span>
                </div>
              </div>
            )}
          </Listbox.Option>
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
