import { MenuIcon, XIcon } from '@heroicons/react/solid';
import {
  UserIcon,
  HomeIcon,
  SearchIcon,
  ShoppingCartIcon,
  LogoutIcon,
  ShoppingBagIcon,
  InformationCircleIcon,
  AcademicCapIcon,
} from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useAuthState } from '@context/auth';
import { useRouter } from 'next/router';
import Profile from '@components/user/profile';
import { useData } from '@context/product';
import { useGetCartInfo } from '@services/shop';
import Cookies from 'js-cookie';
import Image from 'next/image';

export default function Navbar() {
  const router = useRouter();
  const [getCartInfo] = useGetCartInfo();
  const { storage, setValues } = useData();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = router;
  const accessToken = Cookies.get('usukh_token');
  const {
    isAuthenticated: isLoggedIn,
    user: currentUser,
    setLogout,
  } = useAuthState();

  function logOut() {
    setLogout();
    setValues({ cartNumber: '0' });
    setValues({ cartRoute: '' });
    router.push('/');
  }
  // User deer ireh cart number setleh
  function useOnceCall(cb: any, condition: boolean | string[] = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
  }

  useEffect(() => {
    isLoggedIn &&
      getCartInfo(accessToken)
        .then(async (res: any) => {
          if (res && res?.status_code === 200) {
            setValues({ cartNumber: res && res?.result?.items?.length });
          }
        })
        .catch(error => console.log(error?.message));
  }, [storage?.cartNumber]);

  return (
    <div className="w-full">
      <nav
        className={`top-0 z-20 fixed w-full border-b border-opacity-10 bg-gradient-to-r from-[#f91d87] to-[#fa4a9f]`}
      >
        <div
          className={`max-w-7xl mx-auto flex justify-between p-4 items-center`}
        >
          <div className="flex items-center">
            <Link href="/">
              <div className="cursor-pointer flex items-center mr-4 text-white font-normal text-2xl">
                <Image
                  src="/assets/white_logo.png"
                  alt=""
                  className="h-16"
                  width={73}
                  height={64}
                />
              </div>
            </Link>
            <div
              className={`md:flex items-center hidden text-sm text-white font-semibold cursor-pointer ml-4`}
            >
              <Link href="/">
                <div
                  className={`${
                    pathname === '/'
                      ? 'bg-white/30'
                      : 'bg-none hover:bg-white/30'
                  } p-2 rounded`}
                >
                  Нүүр
                </div>
              </Link>
              <Link href="/courses">
                <div
                  className={`${
                    pathname === '/courses'
                      ? 'bg-white/30'
                      : 'bg-none hover:bg-white/30'
                  } p-2 rounded`}
                >
                  Сургалт
                </div>
              </Link>
              <Link href="/shop">
                <div
                  className={`${
                    pathname === '/shop' ||
                    pathname === '/shop/product/[product]'
                      ? 'bg-white/30'
                      : 'bg-none hover:bg-white/30'
                  } p-2 rounded`}
                >
                  Дэлгүүр
                </div>
              </Link>
              <Link href="/aboutus">
                <div
                  className={`${
                    pathname === '/aboutus'
                      ? 'bg-white/30'
                      : 'bg-none hover:bg-white/30'
                  } p-2 rounded`}
                >
                  Бидний тухай
                </div>
              </Link>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Link href="/cart">
              <div
                className={`flex hover:bg-white/30 cursor-pointer p-2 rounded ${
                  pathname === '/cart'
                    ? 'bg-white/30'
                    : 'bg-none hover:bg-white/30'
                }`}
              >
                <ShoppingCartIcon className="h-6 w-6 text-white" />

                {storage?.cartNumber && parseInt(storage?.cartNumber) > 0 ? (
                  <div className="absolute rounded-full bg-white w-5 text-center font-medium text-mainPink text-sm ml-4 -mt-4">
                    {storage?.cartNumber}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Link>
            <div className="hover:bg-white/30 cursor-pointer p-2 rounded">
              <SearchIcon className="h-6 w-6 text-white" />
            </div>
            {isLoggedIn ? (
              <Profile name={currentUser?.user?.first_name} />
            ) : (
              <Link
                href={'/auth/login'}
                className="p-2 rounded-lg border border-white/30 flex items-center gap-2 text-white bg-none hover:bg-white/30 cursor-pointer"
              >
                <UserIcon className="h-5 w-5 text-white" />
                Нэвтрэх
              </Link>
            )}
            <div
              onClick={() => setIsOpen(true)}
              className="md:hidden hover:bg-white/30 p-2 rounded cursor-pointer"
            >
              <MenuIcon className="text-white h-5 w-5" />
            </div>
          </div>
        </div>
        <div
          onClick={() => setIsOpen(false)}
          className={`${
            isOpen ? 'h-screen' : ''
          } top-0 absolute w-full flex justify-end backdrop-blur-2xl bg-black/60`}
        >
          <Transition
            show={isOpen}
            className=""
            enter="transition ease-in-out duration-700 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            {ref => (
              <div
                className="flex justify-center w-72 sm:w-96 md:hidden h-screen bg-white text-cardDate text-sm"
                id="mobile-menu"
              >
                <div ref={ref} className="w-full">
                  <div className="flex justify-between items-center py-5 px-6 border-b">
                    <div className="cursor-pointer flex items-center mr-4 text-button/70 font-medium text-lg gap-2">
                      <img src="/assets/logo.png" alt="" className="h-8" />
                      Өсөх-Од
                    </div>
                    <button onClick={() => setIsOpen(!isOpen)}>
                      <XIcon className="h-5 w-5 text-gray-400 hover:text-gray-800" />
                    </button>
                  </div>
                  <div className="p-3">
                    <Link href="/">
                      <div
                        className={`${
                          pathname === '/'
                            ? 'bg-mainPink/10 text-mainPink'
                            : 'bg-none hover:bg-mainPink/10'
                        } p-3 cursor-pointer rounded flex items-center gap-2`}
                      >
                        <HomeIcon className="h-4 w-4" /> Нүүр
                      </div>
                    </Link>
                    <Link href="/cources">
                      <div
                        className={`${
                          pathname === '/cources'
                            ? 'bg-mainPink/10 text-mainPink'
                            : 'bg-none hover:bg-mainPink/10'
                        } p-3 cursor-pointer rounded flex items-center gap-2`}
                      >
                        <AcademicCapIcon className="h-4 w-4" /> Сургалт
                      </div>
                    </Link>
                    <Link href="/shop">
                      <div
                        className={`${
                          pathname === '/shop' ||
                          pathname === '/shop/product/[product]'
                            ? 'bg-mainPink/10 text-mainPink'
                            : 'bg-none hover:bg-mainPink/10'
                        } p-3 cursor-pointer rounded flex items-center gap-2`}
                      >
                        <ShoppingBagIcon className="h-4 w-4" /> Дэлгүүр
                      </div>
                    </Link>
                    <Link href="/about">
                      <div
                        className={`${
                          pathname === '/about'
                            ? 'bg-mainPink/10 text-mainPink'
                            : 'bg-none hover:bg-mainPink/10'
                        } p-3 cursor-pointer rounded flex items-center gap-2`}
                      >
                        <InformationCircleIcon className="h-4 w-4" /> Бидний
                        тухай
                      </div>
                    </Link>
                  </div>
                  <hr className="border-dashed mb-4" />
                  {isLoggedIn && (
                    <div
                      onClick={() => logOut()}
                      className="cursor-pointer px-3 text-cardDate "
                    >
                      <div className="flex items-center gap-2 font-medium text-sm p-3 hover:bg-mainPink/10 rounded text-red-500">
                        <LogoutIcon className="text-red-500 h-4 w-4" />
                        Системээс гарах
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Transition>
        </div>
      </nav>
    </div>
  );
}
